import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Link, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import theme from '../theme';
import { useParallax } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';

// @material-ui/core components
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Masonry from '@mui/lab/Masonry';
import Avatar from '@mui/material/Avatar';

import Layout from '../components/Layout';
import Features from '../components/Features';
import FeatureImageGrid from '../components/FeatureImageGrid';
import BlogRoll from '../components/BlogRoll';
import TeamList from '../components/TeamList';
import FullWidthImage from '../components/FullWidthImage';

import SectionCarousel from '../components/SectionCarousel';
import ContactDets from '../components/ContactDets';

import SectionNav from '../components/Navbar-v3';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import SectionHeader from '../components/SectionHeader';

import BPLogo from '../img/logo_watermark.png';
import ClockIcon from '../img/icons/Clock.svg';

const Section = styled(Paper)({
	// borderRadius: 12,
	// display: 'flex',
	// justifyContent: 'center',
	// backgroundColor: '#ffffff',
	// zIndex: 2,
});

const SectionFullWidth = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	paddingBottom: theme.spacing(8),
	backgroundColor: theme.palette.background.default,
	position: 'relative',
	top: '-250px',
	// zIndex: 1,
});

const MainContent = styled(Box)({
	// marginTop: 'auto',
	// marginBottom: '50px',
	// margin: 'auto 64px 50px',
});

const ServicesContent = styled(Box)({
	position: 'relative',
	width: '100%',
});

// eslint-disable-next-line
export const IndexPageTemplate = ({
	image,
	title,
	heading,
	subheading,
	mainpitch,
	description,
	intro,
	serviceIntro,
	services,
	gridWidth,
}) => {
	const heroImage = getImage(image) || image;
	const servicesImage = getImage(services.image) || services.image;
	const bodySection = useParallax({ translateY: [-1, -40] });
	// const bottomSection = useParallax({ translateY: [-10, -200] });

	return (
		<>
			<FullWidthImage
				// img={heroImage}
				title={title}
				// subheading={subheading}
				height='100%'
			>
				<Stack direction='row' spacing={6} mt={6}>
					<Button
						variant='contained'
						color='info'
						size='large'
						sx={{ fontSize: '1rem' }}
						href='#services'
					>
						Our Services
					</Button>
					<Button
						variant='contained'
						color='success'
						href='javascript:void(Tawk_API.toggle())'
						size='large'
						sx={{ fontSize: '1rem', color: '#fff' }}
					>
						Get in Touch
					</Button>
				</Stack>
			</FullWidthImage>
			<SectionCarousel />
			<SectionNav />

			<Section
				className='section'
				sx={{ p: { xs: 4, md: 8 }, m: { xs: 2, md: 8 }, borderRadius: 3 }}
				elevation={6}
				ref={bodySection.ref}
			>
				<MainContent>
					<Grid container spacing={6}>
						<Grid item xs={12} sm={12} md={12} className='section'>
							<Typography variant='h3' className='title' align='center'>
								{mainpitch.description}
							</Typography>
							<Typography
								mt={4}
								variant='h1'
								sx={{ fontSize: '2.6rem' }}
								align='center'
								color='secondary'
							>
								{mainpitch.title}
							</Typography>
						</Grid>
						<Features gridItems={intro.blurbs} gridWidth={6} />
					</Grid>
				</MainContent>
			</Section>

			<SectionFullWidth
				className='fulWidthSection'
				elevation={12}
				sx={{ zIndex: 0, scrollMarginTop: '100px' }}
				id='services'
			>
				<ServicesContent px={0} mx={0}>
					<SectionHeader title={serviceIntro.heading} />
					{/* <Grid
						container
						spacing={2}
						sx={{ justifyContent: 'space-between' }}
						// py={0}
					>
						<FeatureImageGrid gridItems={services} gridWidth='4' />
					</Grid> */}

					<Masonry
						columns={{ xs: 1, sm: 2, md: 3 }}
						spacing={2}
						sx={{
							// m: 0,
							pl: 2,
							// width: { xs: '100%', md: '70%' },
						}}
					>
						<FeatureImageGrid
							position='relative'
							gridItems={services}
							gridWidth='4'
						/>
					</Masonry>
				</ServicesContent>
			</SectionFullWidth>
			<ContactDets top='-250px' margin='40px 60px' />
			<Section
				// ref={bottomSection.ref}
				square={true}
				elevation={0}
				sx={{
					backgroundColor: 'transparent',
					position: 'relative',
					top: '-420px',
					marginBottom: '-420px',
				}}
			>
				<MainContent>
					<SectionHeader title='Latest Articles' />
					<BlogRoll />
					<Button
						sx={{ m: '0px auto 70px', display: 'flex', width: 200 }}
						variant='contained'
						component={Link}
						to='/blog'
						size='large'
					>
						Read more
					</Button>
					<SectionHeader title='Meet the Team' />
					<TeamList gridWidth='6' />
				</MainContent>
			</Section>
		</>
	);
};;;;;

IndexPageTemplate.propTypes = {
	image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	title: PropTypes.string,
	heading: PropTypes.string,
	subheading: PropTypes.string,
	mainpitch: PropTypes.object,
	description: PropTypes.object,
	intro: PropTypes.shape({
		blurbs: PropTypes.array,
	}),
};

const IndexPage = ({ data }) => {
	const { homeFront } = data.home;
	const { serviceFront } = data.services;

	return (
		<Layout>
			<IndexPageTemplate
				image={homeFront.image}
				title={homeFront.title}
				heading={homeFront.heading}
				subheading={homeFront.subheading}
				mainpitch={homeFront.mainpitch}
				description={homeFront.description}
				intro={homeFront.intro}
				serviceIntro={serviceFront.intro}
				services={serviceFront.services}
			/>
		</Layout>
	);
};


IndexPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default IndexPage;

export const pageQuery = graphql`
	query IndexPageTemplate {
		home: markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
			homeFront: frontmatter {
				title
				image {
					childImageSharp {
						gatsbyImageData(quality: 100, layout: FULL_WIDTH)
					}
				}
				heading
				subheading
				mainpitch {
					title
					description
				}
				description {
					html
				}
				intro {
					blurbs {
						text {
							html
						}
						heading
					}
					heading
					description {
						html
					}
				}
			}
		}
		services: markdownRemark(
			frontmatter: { templateKey: { eq: "services-page" } }
		) {
			serviceFront: frontmatter {
				title
				image {
					childImageSharp {
						gatsbyImageData(quality: 100, layout: FULL_WIDTH)
					}
				}
				heading
				description {
					html
				}
				intro {
					heading
					description {
						html
					}
				}
				services {
					heading
					description {
						html
					}
					image {
						childImageSharp {
							gatsbyImageData(quality: 100, layout: FULL_WIDTH)
						}
					}
					alt
				}
			}
		}
	}
`;
