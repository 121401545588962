import * as React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import PreviewCompatibleImage from './PreviewCompatibleImage';
import Content, { HTMLContent } from './Content';

import Grid from '@mui/material/Grid';
import NuImageBox from './NuImageBox';
// import NuDialog from './NuDialog';
// import DSPTimetable from './DSP-Timetable';
// import Gallery from './GalleryMasonry';

export const DescriptionHtml = ({ title, content, contentComponent, data }) => {
	const DescContent = contentComponent || Content;

	return <DescContent className='DescriptionHtml' content={content} />;
};

const FeatureImageGrid = ({ gridItems, gridWidth, position }) => (
	<>
		{gridItems.map((item, i) => (
			// <Grid item xs={12} sm={6} md={gridWidth} key={`${item.heading}-item-${i}`}>
			<Box id={item.heading} sx={{ borderRadius: 2 }}>
				<NuImageBox>
					<PreviewCompatibleImage imageInfo={item} position={position} />

					<Typography variant='h4' className='title' px={3}>
						{item.heading}
					</Typography>
					<Typography
						variant='body1'
						component='span'
						dangerouslySetInnerHTML={{ __html: item.description.html }}
						sx={{ zIndex: 0 }}
						px={3}
					/>
				</NuImageBox>
			</Box>
			// </Grid>
		))}
	</>
);

FeatureImageGrid.propTypes = {
	gridItems: PropTypes.arrayOf(
		PropTypes.shape({
			image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
			text: PropTypes.object,
			heading: PropTypes.string,
		})
	),
};

export default FeatureImageGrid;
