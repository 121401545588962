import React from 'react';
import { styled } from '@mui/system';
import { Link } from 'gatsby';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Palette } from '@mui/icons-material';

const ImageBox = styled(Box)(({ theme }) => ({
	position: 'relative',
	display: 'flex',
	width: '100%',
	height: '100%',
	backgroundPosition: 'center',
	backgroundSize: 'cover',
	zIndex: 0,
	flexDirection: 'column',
	overflow: 'hidden',
	padding: theme.spacing(1),
	paddingBottom: theme.spacing(1),
	transition: 'all 0.5s ease',
	color: theme.palette.primary.main,
	borderRadius: theme.spacing(1),
	img: {
		height: '100%',
		position: 'absolute',
		// zIndex: '-1', add this for background images and hover overlay
	},
	'&:hover': {
		transition: 'all 0.5s ease',
		color: theme.palette.primary.main,
		'& .filter': {
			backgroundColor: 'rgba(0,0,0, 0.1)',
			transition: 'all 0.5s ease',
			transform: 'scale(1.2)',
		},
	},
}));

const ImageBoxComponent = ({ linkto, title, subtext, children, flipText }) => {
	return (
		<ImageBox
			elevation='3'
			variant='contained'
			component={linkto ? Link : null}
			to={linkto}
		>
			<Box
				component='div'
				className='filter'
				sx={{
					position: 'absolute',
					zIndex: '2',
					backgroundColor: 'rgba(255,255,255, 0.5)',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					textAlign: 'center;',
					zIndex: '0',
				}}
			/>
			<Typography
				variant='h3'
				align='center'
				sx={{
					zIndex: 0,
					fontWeight: 600,
					fontSize: '1.7rem',
					textTransform: 'uppercase',
				}}
			>
				{title}
			</Typography>
			<Typography
				variant='body2'
				align='center'
				mt={subtext ? 2 : ''}
				sx={{ fontSize: '0.7rem', zIndex: 0 }}
			>
				{subtext}
			</Typography>
			{children}
		</ImageBox>
	);
};

export default ImageBoxComponent;
